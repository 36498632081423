import styled from "styled-components"

const Underlining = styled.span`
  box-shadow: inset 0 0 ${({ big }) => (big ? "-1.5rem" : "-.5rem")}
    ${({ theme, color }) => theme.colors[color] || color};
  transition: box-shadow 1s linear;
  &:hover {
    box-shadow: inset 0 ${({ big }) => (big ? "-2rem" : "-1rem")} 0
      ${({ theme, hoverColor }) => theme.colors[hoverColor] || hoverColor};
  }
`

export default Underlining
